import {USER_ROLES_MODEL_AS_CODE} from '@/_new-code/products/gated-content/roles'
import {
	AppPermissionMaping,
	applicationPermissionsCodeName,
	applicationPermissionsGuidelines,
	applicationPermissionsName,
	authenticationGateCodeName,
	authenticationGateMultiChoiceElement,
	authenticationGateName,
	userRolesCodeName,
	userRolesGuidelines,
	userRolesName,
} from '@/model-as-code/allTowers/configs/authenticationGateConstants'
import type {ModelAsCode} from '@elanco/model-as-code'

const farmPortalAppPermissionCodename = 'farm_portal'
const techTwoTechAppPermissionCodename = 'tech_two_tech'
export const FarmTowerAppPermissionMapping: AppPermissionMaping = {
	mapping: {
		[farmPortalAppPermissionCodename]: 'farmPortal',
		[techTwoTechAppPermissionCodename]: 'tech2Tech',
	},
}

const modelAuthenticationGate: ModelAsCode = (builder) => ({
	name: authenticationGateName,
	codename: authenticationGateCodeName,
	modelType: 'contentTypeSnippet',
	elements: [
		builder.multipleChoiceElement(authenticationGateMultiChoiceElement),
		builder.multipleChoiceElement({
			mode: 'multiple',
			options: [
				{
					codename: farmPortalAppPermissionCodename,
					name: 'Farm Portal',
				},
				{
					codename: techTwoTechAppPermissionCodename,
					name: 'Tech 2 Tech',
				},
			],
			name: applicationPermissionsName,
			guidelines: applicationPermissionsGuidelines,
			is_required: false,
			type: 'multiple_choice',
			codename: applicationPermissionsCodeName,
		}),
		builder.multipleChoiceElement({
			mode: 'multiple',
			options: USER_ROLES_MODEL_AS_CODE,
			name: userRolesName,
			guidelines: userRolesGuidelines,
			is_required: false,
			type: 'multiple_choice',
			codename: userRolesCodeName,
		}),
	],
})

export default modelAuthenticationGate

import type {ModelAsCodeMultipleChoiceElementData} from '@elanco/model-as-code'

// In this mapping, the first string is the Kontent codename and the
// second string is the fieldname in the CDC app permissions object.
export type AppPermissionMaping = {mapping: Record<string, string>}
export type UserRolesMapping = {mapping: Record<string, string>}

export const authenticationGateName: string =
	'Authentication Gate Level Options'
export const authenticationGateForComponentName: string =
	'Authentication Gate Level Options - For Components'
export const authenticationGateCodeName: string = 'authentication_gate'
export const authenticationGateForComponentsCodeName: string =
	'authentication_gate_component'
export const authenticationGateMultiChoiceElement: ModelAsCodeMultipleChoiceElementData =
	{
		mode: 'single',
		options: [
			{codename: 'n0___anonymous_users', name: '0 - Anonymous Users'},
			{
				codename: 'n1___self_confirmation_required',
				name: '1 - Self-Confirmation Required',
			},
			{codename: 'n2___logged_in', name: '2 - Logged In'},
			{
				codename: 'n3___logged_in_with_a_validated_account',
				name: '3 - Logged In with a Validated Account',
			},
		],
		name: 'Authentication Gate Level',
		guidelines:
			'Select the level of authentication required for a user to see this page. If no option is selected, the global setting will be used.',
		is_required: false,
		type: 'multiple_choice',
		codename: 'level',
	}

export const applicationPermissionsName: string = 'Application Permissions'
export const applicationPermissionsGuidelines: string =
	'Select Application Permissions that are required to see this resource. If an app permission is selected, the logged in user will require the corresponding app permission(s) in CDC to see the resource. If multiple are selected the user must have all corresponding permissions to see the resource. This selection is only applicable when Gate Level 3 is selected. If the user does not have the correct permission to view the content then they will be redirected as per the URL in the "Unauthenticated Redirect URL". If this field is not populated, the user will be redirected as per the "Validation Error URL" specified in the sites Global Config under that "Gated Content" tab.'
export const applicationPermissionsCodeName: string = 'application_permissions'

export const userRolesName: string = 'User Roles'
export const userRolesGuidelines: string =
	'Select User Roles that are required to see this resource. If a user role is selected, the logged in user will require the corresponding app role(s) in CDC to see the resource. If multiple roles are selected then the user must have any one of the selected roles to see the resource. This selection is only applicable when Gate Level 3 is selected. If the user does not have the correct role to view the content then they will be redirected as per the URL in the "Unauthenticated Role Redirect URL". If this field is not populated, the user will be redirected as per the "Validation Error URL" specified in the sites Global Config under that "Gated Content" tab. Complete user guide for Role-based Gated Content is available on the Digital hub - http://tinyurl.com/elancorbgc'
export const userRolesGuidelinesForComponents: string =
	'Select User Roles that are required to see this resource. If a user role is selected, the logged in user will require the corresponding app role(s) in CDC to see the resource. If multiple roles are selected then the user must have any one of the selected roles to see the resource. If no roles are selected, the block content will be visible to all users. Complete user guide for Role-based Gated Content is available on the Digital hub - http://tinyurl.com/elancorbgc'
export const userRolesCodeName: string = 'user_roles'

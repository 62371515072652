import {USER_ROLES_MODEL_AS_CODE} from '@/_new-code/products/gated-content/roles'
import {
	AppPermissionMaping,
	applicationPermissionsCodeName,
	applicationPermissionsGuidelines,
	applicationPermissionsName,
	userRolesCodeName,
	userRolesGuidelines,
	userRolesName,
	authenticationGateCodeName,
	authenticationGateMultiChoiceElement,
	authenticationGateName,
} from '@/model-as-code/allTowers/configs/authenticationGateConstants'
import type {ModelAsCode} from '@elanco/model-as-code'

const vetPortalAppPermissionCodename = 'vet_portal'
const dvmRebateAppPermissionCodename = 'dvm_rebate'
const dvmEducationAppPermissionCodename = 'dvm_education'
export const VetTowerAppPermissionMapping: AppPermissionMaping = {
	mapping: {
		[vetPortalAppPermissionCodename]: 'vetPortal',
		[dvmRebateAppPermissionCodename]: 'dvmRebate',
		[dvmEducationAppPermissionCodename]: 'dvmEducation',
	},
}

const modelAuthenticationGate: ModelAsCode = (builder) => ({
	name: authenticationGateName,
	codename: authenticationGateCodeName,
	modelType: 'contentTypeSnippet',
	elements: [
		builder.multipleChoiceElement(authenticationGateMultiChoiceElement),
		builder.multipleChoiceElement({
			mode: 'multiple',
			options: [
				{codename: vetPortalAppPermissionCodename, name: 'Vet Portal'},
				{codename: dvmRebateAppPermissionCodename, name: 'Rebates'},
				{
					codename: dvmEducationAppPermissionCodename,
					name: 'Education',
				},
			],
			name: applicationPermissionsName,
			guidelines: applicationPermissionsGuidelines,
			is_required: false,
			type: 'multiple_choice',
			codename: applicationPermissionsCodeName,
		}),
		builder.multipleChoiceElement({
			mode: 'multiple',
			options: USER_ROLES_MODEL_AS_CODE,
			name: userRolesName,
			guidelines: userRolesGuidelines,
			is_required: false,
			type: 'multiple_choice',
			codename: userRolesCodeName,
		}),
	],
})

export default modelAuthenticationGate

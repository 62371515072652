import type {Elements} from '@kontent-ai/delivery-sdk'
import {
	nodeParse,
	transformToPortableText,
} from '@kontent-ai/rich-text-resolver'
import type {
	PortableTextComponent,
	PortableTextImage,
	PortableTextExternalLink,
	PortableTextInternalLink,
	PortableTextTable,
} from '@kontent-ai/rich-text-resolver'
import type {
	PortableTextListComponent,
	PortableTextMarkComponent,
	PortableTextTypeComponent,
	PortableTextTypeComponentProps,
} from '@portabletext/react'
import {PortableText} from '@portabletext/react'
import {toHTML, uriLooksSafe} from '@portabletext/to-html'
import Link from 'next/link'
import type {ElementType} from 'react'
import Image from 'next/image'
import {terse} from '@/_new-code/services/kontent-ai/terser'
import type {BlockMapper as Mapper} from '@/_new-code/services/kontent-ai/mapper'
import type {
	GlobalConfigContentItem,
	AdvancedPageTemplateContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {loadImageFromKontentAI} from '@/imageLoader'

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- shadowing toHTML function
function toHTMLWithMarks(value: any): string {
	return toHTML(value, {
		components: {
			marks: {
				sup: (e) => `<sup>${e.text}</sup>`,
				sub: (e) => `<sub>${e.text}</sub>`,
				link: ({
					children,
					value: v,
				}: {
					children: string
					value?: {
						href: string
						rel: string
						'data-new-window': 'true' | 'false'
						target: string
					}
				}) => {
					if (!v) return children

					const unsafeUri = v.href

					// If URI looks unsafe, render children (text) without the link
					if (!uriLooksSafe(unsafeUri)) {
						return children
					}

					return `<a href="${v.href}" rel=${v.rel} target=${v.target} data-new-window="${v['data-new-window']}">${children}</a>`
				},
				internalLink: (e) => e.text,
			},
		},
	})
}

const BlockType: PortableTextTypeComponent = ({value}) => {
	const html = toHTMLWithMarks(value)

	return (
		<div
			className="rich-content"
			dangerouslySetInnerHTML={{__html: html}}
		/>
	)
}

const SubMark: PortableTextMarkComponent = ({children}) => <sub>{children}</sub>
const SupMark: PortableTextMarkComponent = ({children}) => <sup>{children}</sup>

const ExternalLinkMark: PortableTextMarkComponent<PortableTextExternalLink> = ({
	children,
	value,
}) => {
	if (!value) return <>{children}</>

	const isPDF =
		value.href?.includes('.pdf') ?? value.href?.includes('elancolabels.com')

	return (
		<Link
			href={value.href ?? ''}
			passHref
			rel={
				// eslint-disable-next-line no-nested-ternary -- This is clean syntax
				value.rel
					? value.rel
					: isPDF
						? 'noopener noreferrer'
						: undefined
			}
			target={isPDF ? '_blank' : undefined}
			title={value.title}
		>
			{}
			{children}
		</Link>
	)
}

const InternalLinkMark: PortableTextMarkComponent<PortableTextInternalLink> = ({
	children,
}) => <>{children}</>

const BulletList: PortableTextListComponent = ({value}) => {
	const html = toHTMLWithMarks(value)
	const cleanedHtml = html
		.replaceAll('<ul>', '')
		.replaceAll('</ul>', '')
		.replaceAll('<li><br/></li>', '')

	return (
		<ul
			className="rich-content mb-4 list-disc px-5"
			dangerouslySetInnerHTML={{__html: cleanedHtml}}
		/>
	)
}

const NumberList: PortableTextListComponent = ({value}) => {
	const html = toHTMLWithMarks(value)
	const cleanedHtml = html
		.replaceAll('<ol>', '')
		.replaceAll('</ol>', '')
		.replaceAll('<li><br/></li>', '')

	return (
		<ol
			className="rich-content list-decimal px-5"
			dangerouslySetInnerHTML={{__html: cleanedHtml}}
		/>
	)
}

/**
 * Utility Component for rendering the content of a Kontent.ai Rich Text element
 *
 * @example
 * ```
 * import type {IContentItem} from '@kontent-ai/delivery-sdk'
 *
 * type ArticleContentItem = IContentItem<{
 * 	content: Elements.RichTextElement
 * }>
 *
 * const ArticleBlock: Block<ArticleContentItem> = ({
 * 	block,
 * 	BlockMapper,
 * }) => (
 * 	<RichTextRenderer
 * 		BlockMapper={BlockMapper}
 * 		element={block.elements.content}
 *  />
 * )
 * ```
 *
 * @see https://www.npmjs.com/package/\@portabletext/react
 * @see https://github.com/kontent-ai/rich-text-resolver-js
 */
export function RichTextRenderer<T extends ElementType>({
	element,
	BlockMapper,
	page,
	removeEmptyLines = false,
	className,
	as,
	...context
}: {
	element: Elements.RichTextElement
	BlockMapper: typeof Mapper
	page: Tersed<AdvancedPageTemplateContentItem>
	globalConfig: Tersed<GlobalConfigContentItem>
	removeEmptyLines?: boolean
	className?: string
	as?: T
}): JSX.Element {
	const rawNode = removeEmptyLines
		? element.value.replaceAll('<p><br></p>', '')
		: element.value
	const parsedTree = nodeParse(rawNode)
	const portableText = transformToPortableText(parsedTree)
	const Component = as ?? 'div'
	return (
		<Component className={className}>
			<PortableText
				components={{
					types: {
						// eslint-disable-next-line react/no-unstable-nested-components -- TODO: is it possible not to?
						component: ({
							value,
						}: PortableTextTypeComponentProps<PortableTextComponent>) => {
							const item = element.linkedItems.find(
								(i) =>
									i.system.codename === value.component._ref
							)

							if (!item) return null

							const tersedItem = terse(item)

							return (
								<div className="m-auto w-full">
									<BlockMapper
										blocks={[tersedItem]}
										isComponent
										page={page}
										{...context}
									/>
								</div>
							)
						},
						// eslint-disable-next-line react/no-unstable-nested-components -- TODO: is it possible not to?
						image: ({
							value,
						}: PortableTextTypeComponentProps<PortableTextImage>) => {
							const {alt, url, _ref} = value.asset
							const image = element.images.find(
								(i) => i.imageId === _ref
							)

							if (!image?.height || !image.width) {
								return (
									// eslint-disable-next-line @next/next/no-img-element -- If we don't have width or height, we cannot make it work in this scenario
									<img
										alt={alt}
										className="mx-auto"
										src={url}
									/>
								)
							}

							return (
								<Image
									alt={alt ?? ''}
									className="mx-auto"
									height={image.height}
									loader={loadImageFromKontentAI}
									src={url}
									width={image.width}
								/>
							)
						},
						// eslint-disable-next-line react/no-unstable-nested-components -- temp
						table: ({
							value,
						}: PortableTextTypeComponentProps<PortableTextTable>) => {
							const {rows} = value
							return (
								<div
									className="content content--remove-bottom-margin container flex flex-col overflow-y-scroll text-left md:overflow-y-auto"
									id="RT-container"
									style={{
										overflowWrap: 'anywhere',
									}}
								>
									<div className="table-container w-full">
										<table className="w-full">
											<tbody>
												{rows.map((row, rowIndex) => (
													<tr
														key={
															row._key ?? rowIndex
														}
													>
														{row.cells.map(
															(
																cell,
																cellIndex
															) => (
																<td
																	className="static max-w-[350px] text-left align-top"
																	dangerouslySetInnerHTML={{
																		__html: toHTMLWithMarks(
																			cell.content
																		),
																	}}
																	key={
																		cell._key ??
																		`${String(
																			rowIndex
																		)}-${String(
																			cellIndex
																		)}`
																	}
																/>
															)
														)}
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							)
						},
						block: BlockType,
					},
					marks: {
						sub: SubMark,
						sup: SupMark,
						link: ExternalLinkMark,
						internalLink: InternalLinkMark,
					},
					list: {
						bullet: BulletList,
						number: NumberList,
					},
				}}
				value={portableText}
			/>
		</Component>
	)
}

import {ElementType} from '@kontent-ai/delivery-sdk'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'

/**
 * The Terser transforms raw data from Kontent.ai into a smaller, more usable format
 */
export function terse<TContentItem extends IContentItem = IContentItem>(
	contentItem: TContentItem
): Tersed<TContentItem> {
	const elements = Object.fromEntries(
		Object.entries(contentItem.elements).map(([key, element]) => {
			switch (element.type) {
				case ElementType.ModularContent: {
					const el = element as Elements.LinkedItemsElement
					return [
						key,
						el.linkedItems.map((item) => terse(item)),
					] as const
				}

				case ElementType.Asset: {
					const el = element as Elements.AssetsElement
					const newAsset = el.value.map((asset) => ({
						description: asset.description ?? '',
						height: asset.height ?? null,
						width: asset.width ?? null,
						url: asset.url,
					}))
					return [key, newAsset]
				}

				case ElementType.RichText: {
					const el = element as Elements.RichTextElement
					return [key, el]
				}

				default: {
					return [key, element.value]
				}
			}
		})
	)

	const system = {
		type: contentItem.system.type,
		codename: contentItem.system.codename,
		id: contentItem.system.id,
		name: contentItem.system.name,
	}

	return {
		// @ts-expect-error -- TODO: more type gymnastics
		elements,
		system,
	}
}

import {
	AppPermissionMaping,
	applicationPermissionsCodeName,
	applicationPermissionsGuidelines,
	applicationPermissionsName,
	authenticationGateCodeName,
	authenticationGateMultiChoiceElement,
	authenticationGateName,
} from '@/model-as-code/allTowers/configs/authenticationGateConstants'
import type {ModelAsCode} from '@elanco/model-as-code'

const petPortalAppPermissionCodename = 'pet_portal'
export const PetTowerAppPermissionMapping: AppPermissionMaping = {
	mapping: {
		[petPortalAppPermissionCodename]: 'petPortal',
	},
}

const modelAuthenticationGate: ModelAsCode = (builder) => ({
	name: authenticationGateName,
	codename: authenticationGateCodeName,
	modelType: 'contentTypeSnippet',
	elements: [
		builder.multipleChoiceElement(authenticationGateMultiChoiceElement),
		builder.multipleChoiceElement({
			mode: 'multiple',
			options: [
				{codename: petPortalAppPermissionCodename, name: 'Pet Portal'},
			],
			name: applicationPermissionsName,
			guidelines: applicationPermissionsGuidelines,
			is_required: false,
			type: 'multiple_choice',
			codename: applicationPermissionsCodeName,
		}),
	],
})

export default modelAuthenticationGate

import {useEffect, useState} from 'react'

const cookieValueMap = {
	'permit 1,2,3': 'ALL_COOKIES',
	'permit 1,2 functional': 'FUNCTIONAL_COOKIES',
	'permit 1,3': 'ADVERTISE_COOKIES',
	'permit 1 required': 'DEFAULT_COOKIES',
} as const

type TrustArcConsent = keyof typeof cookieValueMap
type ConsentLevel = (typeof cookieValueMap)[TrustArcConsent]

/**
 * Hook to evaluate the user's current accepted level of cookie preferences
 *
 * @returns The Consent Level that the user has currently accepted
 */
export function useTrustArcConsent(): ConsentLevel {
	const [trustArcConsent, setTrustArcConsent] = useState<TrustArcConsent>()
	const [latestCookie, setLatestCookie] = useState('')
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary for SSR
		if (document !== undefined) {
			setLatestCookie(document.cookie)
		}
		const detectCookieUpdate = (): void => {
			document.cookie !== latestCookie && setLatestCookie(document.cookie)
		}
		const interval = setInterval(detectCookieUpdate, 1000)
		setIntervalId(interval)

		return () => {
			clearInterval(interval)
		}
	}, [latestCookie])

	useEffect(() => {
		if (trustArcConsent !== undefined) {
			clearInterval(intervalId)
		}
	}, [intervalId, trustArcConsent])

	useEffect(() => {
		const cookieValue = latestCookie
			.split('; ')
			.find((row) => row.startsWith('cmapi_cookie_privacy='))
			?.split('=')[1]
		setTrustArcConsent(cookieValue as TrustArcConsent)
	}, [latestCookie])

	return cookieValueMap[trustArcConsent ?? 'permit 1 required']
}

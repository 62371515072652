import {USER_ROLES} from '@/_new-code/products/gated-content/roles'
import type {Data as User} from '@/models/CurrentUserContext'
import {env} from '@/utils/env/client.mjs'

// TODO: refactor this into a CDC Service

export const getUserRoles = (user: User): string[] | null => {
	if (user.role && user.role2) {
		return [user.role, user.role2]
	}
	if (user.role) {
		return [user.role]
	}
	return null // TODO: can this be an empty list?
}

export const areRolesSufficient = (
	requiredRoles: string[] | undefined,
	userRoles: string[]
): boolean => {
	// If the user is on the Pet tower, which currently does not have role-based access enabled, the user should be able to access the content
	if (env.NEXT_PUBLIC_TOWER === 'pet') return true

	// If there are no required roles, the user should be able to access the content
	if (!requiredRoles || requiredRoles.length === 0) {
		return true
	}

	// Otherwise, the user must have at least one of the roles required
	const matchingOptions = requiredRoles
		.map((pageRole) =>
			USER_ROLES.find((userRole) => userRole.codename === pageRole)
		)
		.filter(Boolean)
		.map((userRole) => ({
			...userRole,
			cdcRoles: userRole.cdcRoles.map((cdcRole) => cdcRole.toLowerCase()), // CDC roles are case insensitive, so convert all to lower case
		}))

	// Therefore, at least one role required for the content must match at least one role the user has
	return matchingOptions.some((matchingOption) =>
		userRoles.some(
			(userRole) =>
				matchingOption.cdcRoles.includes(userRole.toLowerCase()) // As CDC roles are case insensitive, always check against lower case versions
		)
	)
}

export const doesUserHaveSufficientRoles = (
	requiredRoles: string[] | undefined,
	user: User
): boolean => {
	const userRoles = getUserRoles(user) ?? []
	return areRolesSufficient(requiredRoles, userRoles)
}
